import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/roboto/300.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/roboto/400.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/roboto/500.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/roboto/700.css");
